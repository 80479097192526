import React from 'react'

import { Flexbox, Paragraph } from '@stage-ui/core'

import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import IconGlass, { glassIcons } from '@src/components/IconGlass'
import { metrikaGoal } from '@src/utils/metrikaGoal'

type FeatureBlockProps = {
  title: string
  icon: keyof typeof glassIcons
} & FlexboxTypes.Props

const FeatureBlock = (props: FeatureBlockProps) => {
  const { title, icon, ...restProps } = props
  return (
    <Flexbox
      column
      h={['7.5rem', '6.5rem']}
      borderColor="gray200"
      borderWidth="0.0625rem"
      borderStyle="solid"
      borderRadius="0.5rem"
      p={['1rem', '0.5rem']}
      backgroundColor="surface"
      onClick={() => {
        metrikaGoal('clickFeatureBlock')
      }}
      {...restProps}
    >
      <IconGlass
        type={icon}
        title={title}
        alt={icon}
        size="3.125rem"
        mb={['1.125rem', '0.5rem']}
      />
      <Paragraph w={['12rem', 'auto']} lineHeight={1.4} size={['1.125rem', '0.875rem']}>
        {title}
      </Paragraph>
    </Flexbox>
  )
}

export default FeatureBlock
