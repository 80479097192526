import React from 'react'

import { Flexbox, Grid } from '@stage-ui/core'

import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import FeatureBlock from '@src/components/FeatureBlock'
import PageTitle from '@src/components/PageTitle'

const S10Features = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle
        id="quality"
        attributes={{
          'data-id': 'quality',
        }}
        label="Фичи"
        title="Функционал системы"
      />
      <Grid templateColumns={['1fr 1fr 1fr 1fr', ' 1fr 1fr']} gap="0.5rem">
        <FeatureBlock title="Защита синхронизации" icon="info" />
        <FeatureBlock title="Онлайн отслеживание" icon="play" />
        <FeatureBlock title="Присвоение алиаса" icon="crown" />
        <FeatureBlock title="Расширенный поиск" icon="search" />
        <FeatureBlock title="Интуитивный интерфейс" icon="avatar" />
        <FeatureBlock title="Аналитика по клиенту" icon="round" />
        <FeatureBlock title="Быстрая навигация " icon="pin" />
        <FeatureBlock title="Фотоотчет товаров в заказе" icon="camera" />
        <FeatureBlock title="Информационные сообщения в 1С" icon="bell" />
        <FeatureBlock title="Быстрый доступ ко всем заказам" icon="like" />
        <FeatureBlock title="Умный алгоритм сборки" icon="bulb" />
        <FeatureBlock title="Онлайн синхронизация" icon="laptop" />
        <FeatureBlock title="Система оценки клиентов" icon="heart" />
        <FeatureBlock title="Фиксация товара с докупкой" icon="video" />
        <FeatureBlock title="Функция - умная докупка" icon="settings" />
        <FeatureBlock title="Оптимизация маршрутов" icon="truck" />
        <FeatureBlock title="Автоматическое обновление" icon="fire" />
        <FeatureBlock title="История всех заказа" icon="diagram" />
        <FeatureBlock title="Система диалогов, чаты" icon="chat" />
        <FeatureBlock title="Уведомления клиента" icon="email" />
        <FeatureBlock title="Масштабируемые кластеры" icon="puzzle" />
        <FeatureBlock title="Защищенные каналы связи" icon="spark" />
        <FeatureBlock title="Контроль качества на линии" icon="wrench" />
        <FeatureBlock title="Онлайн контроль статусов" icon="star" />
        <FeatureBlock title="Автоматическое заполнение" icon="drop" />
        <FeatureBlock title="Низкий износ оборудования " icon="badge" />
        <FeatureBlock title="Цветовая индикация" icon="lightning" />
        <FeatureBlock title="Контроль взвешивания" icon="ok" />
        <FeatureBlock title="Индикация не назначенных " icon="flag" />
        <FeatureBlock title="Онлайн контроль команды" icon="eye" />
        <FeatureBlock title="Отслеживание докупок" icon="navigator" />
        <FeatureBlock title="Не дорогое оборудование" icon="wallet" />
      </Grid>
    </Flexbox>
  )
}

export default S10Features
